.tab-selector-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/production%20process-1500h.png");
}
 
.tab-selector-frame135 {
  gap: 60px;
  width: 1152px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.tab-selector-frame141 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.tab-selector-text10 {
  color: var(--dl-color-white-white);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-frame3481 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.15000000596046448);
}
 
.tab-selector-card-production-button1 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
  background-color: #FFFFFF;
}
 
.tab-selector-text11 {
  color: #27272A;
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-ic-module-button1 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-text12 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-smart-card-button1 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-text13 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-frame3482 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.15000000596046448);
}
 
.tab-selector-card-production-button2 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  transition: 0.3s;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-card-production-button2:active {
  color: #27272A;
  background-color: white;
}
 
.tab-selector-text14 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-ic-module-button2 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
  background-color: #ffffff;
}
 
.tab-selector-text15 {
  color: #27272A;
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-smart-card-button2 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-text16 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-frame3483 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.15000000596046448);
}
 
.tab-selector-card-production-button3 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  transition: 0.3s;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-card-production-button3:active {
  color: #27272A;
  background-color: white;
}
 
.tab-selector-text17 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-ic-module-button3 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-text18 {
  color: var(--dl-color-neutral-300);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-smart-card-button3 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
  background-color: #ffffff;
}
 
.tab-selector-text19 {
  color: #27272A;
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-card-production1 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-frame3531 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-frame1171 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame1191 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text20 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text21 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1441 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12010 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text22 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text23 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1451 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12011 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text25 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame3541 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.tab-selector-frame1461 {
  gap: 16px;
  width: 370px;
  height: 188px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12012 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text27 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1452 {
  gap: 16px;
  width: 370px;
  height: 188px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12013 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text29 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-card-production2 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-frame3532 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-frame1172 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame1192 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text30 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text31 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1442 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12014 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text32 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text33 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1453 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12015 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text34 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text35 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame3542 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.tab-selector-frame1462 {
  gap: 16px;
  width: 370px;
  height: 188px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12016 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text36 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text37 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1454 {
  gap: 16px;
  width: 370px;
  height: 188px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12017 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text39 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-ic-module {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-frame3533 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-frame1173 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame1193 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text41 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1443 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12018 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text42 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text43 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1455 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12019 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text44 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text45 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame3543 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
 
.tab-selector-frame1463 {
  gap: 16px;
  width: 370px;
  height: 206px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12020 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text46 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text47 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-frame1456 {
  gap: 16px;
  width: 370px;
  height: 206px;
  display: flex;
  padding: 20px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.10000000149011612);
}
 
.tab-selector-frame12021 {
  gap: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-secondary-500);
}
 
.tab-selector-text48 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text49 {
  color: var(--dl-color-neutral-300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-text50 {
  display: inline-block;
}
 
.tab-selector-text51 {
  display: inline-block;
}
 
.tab-selector-text52 {
  display: inline-block;
}
 
.tab-selector-text53 {
  display: inline-block;
}
 
.tab-selector-text54 {
  display: inline-block;
}
 
.tab-selector-text55 {
  display: inline-block;
}
 
.tab-selector-text56 {
  display: inline-block;
}
 
.tab-selector-text57 {
  display: inline-block;
}
 
.tab-selector-text58 {
  display: inline-block;
}
 
.tab-selector-text59 {
  display: inline-block;
}
 
.tab-selector-text60 {
  display: inline-block;
}
 
.tab-selector-text61 {
  display: inline-block;
}
 
.tab-selector-text62 {
  display: inline-block;
}
 
.tab-selector-text63 {
  display: inline-block;
}
 
.tab-selector-text64 {
  display: inline-block;
}
 
.tab-selector-text65 {
  display: inline-block;
}
 
.tab-selector-text66 {
  display: inline-block;
}
 
.tab-selector-text67 {
  display: inline-block;
}
 
.tab-selector-text68 {
  display: inline-block;
}
 
.tab-selector-text69 {
  display: inline-block;
}
 
.tab-selector-text70 {
  display: inline-block;
}
 
.tab-selector-text71 {
  display: inline-block;
}
 
.tab-selector-text72 {
  display: inline-block;
}
 
.tab-selector-text73 {
  display: inline-block;
}
 
.tab-selector-text74 {
  display: inline-block;
}
 
.tab-selector-text75 {
  display: inline-block;
}
 
.tab-selector-text76 {
  display: inline-block;
}
 
.tab-selector-text77 {
  display: inline-block;
}
 
.tab-selector-text78 {
  display: inline-block;
}
 
.tab-selector-text79 {
  display: inline-block;
}
 
.tab-selectorroot-class-name {
  padding-top: 100px;
  padding-bottom: 100px;
}
 
@media(max-width: 1195px) {
  .tab-selector-frame135 {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}
 
@media(max-width: 845px) {
  .tab-selector-frame135 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .tab-selector-frame3531 {
    flex-direction: column;
  }
  .tab-selector-frame3541 {
    width: 100%;
    flex-direction: column;
  }
  .tab-selector-frame1461 {
    width: 100%;
    height: auto;
  }
  .tab-selector-frame1452 {
    width: 100%;
    height: auto;
  }
  .tab-selector-frame3532 {
    flex-direction: column;
  }
  .tab-selector-frame3542 {
    width: 100%;
    flex-direction: column;
  }
  .tab-selector-frame1462 {
    width: 100%;
    height: auto;
  }
  .tab-selector-frame1454 {
    width: 100%;
    height: auto;
  }
  .tab-selector-frame3533 {
    align-items: center;
    flex-direction: column;
  }
  .tab-selector-frame3543 {
    align-items: center;
    flex-direction: column;
  }
  .tab-selector-frame1463 {
    width: 100%;
    height: auto;
  }
  .tab-selector-frame1456 {
    width: 100%;
    height: auto;
    align-self: stretch;
    align-items: flex-start;
  }
  .tab-selectorroot-class-name {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
