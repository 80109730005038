.tab-selector-2-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-product {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 80px 0 60px;
  overflow: hidden;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-white-white);
}
 
.tab-selector-2-frame133 {
  gap: 80px;
  width: 1152px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.tab-selector-2-frame362 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-text10 {
  color: var(--dl-color-neutral-800);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3481 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-for-telecomunication-button1 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  background: #65BC46;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text11 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Lexend";
  line-height: normal;
  letter-spacing: 0em;
}
 
.tab-selector-2-for-banking-button1 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text12 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-for-goverment1 {
  gap: 10px;
  curosr: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text13 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-frame3482 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-for-telecomunication-button2 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text14 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-for-banking-button2 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  background: #65BC46;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text15 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Lexend";
  line-height: normal;
  letter-spacing: 0em;
}
 
.tab-selector-2-for-goverment2 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text16 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-frame3483 {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 999px;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-for-telecomunication-button3 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text17 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-for-banking-button3 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text18 {
  color: var(--dl-color-neutral-400);
  height: auto;
  text-align: center;
  line-height: normal;
}
 
.tab-selector-2-for-goverment3 {
  gap: 10px;
  display: flex;
  padding: 10px 16px;
  background: #65BC46;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
}
 
.tab-selector-2-text19 {
  color: #ffffff;
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Lexend";
  line-height: normal;
  letter-spacing: 0em;
}
 
.tab-selector-2-frame3731 {
  gap: 60px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-image1 {
  width: 500px;
  height: 600px;
}
 
.tab-selector-2-frame3741 {
  gap: 40px;
  width: 592px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.tab-selector-2-frame3751 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-text20 {
  color: var(--dl-color-neutral-800);
  height: auto;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text21 {
  color: var(--dl-color-danger-600main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text22 {
  color: var(--dl-color-neutral-500);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3781 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-frame3791 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-frame1271 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-simcard {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text23 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text24 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3761 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-lightbulb1 {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text25 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text26 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3771 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-box1 {
  top: 10px;
  left: 417px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text27 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text28 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3732 {
  gap: 60px;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-image2 {
  width: 500px;
  height: 600px;
}
 
.tab-selector-2-frame3742 {
  gap: 40px;
  width: 592px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.tab-selector-2-frame3752 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-text29 {
  color: var(--dl-color-neutral-800);
  height: auto;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text30 {
  color: var(--dl-color-secondary-600main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text31 {
  color: var(--dl-color-neutral-500);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3782 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-frame3792 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-frame1272 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-creditcard {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text32 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text33 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3762 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-lightbulb2 {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text34 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text35 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3772 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-box2 {
  top: 25px;
  left: 417px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text36 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text37 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3733 {
  gap: 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-image3 {
  width: 500px;
  height: 600px;
}
 
.tab-selector-2-frame3743 {
  gap: 40px;
  width: 592px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.tab-selector-2-frame3753 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-text38 {
  color: var(--dl-color-neutral-800);
  height: auto;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text39 {
  color: var(--dl-color-primary-500main);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text40 {
  color: var(--dl-color-neutral-500);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3783 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.tab-selector-2-frame3793 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-frame1273 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-processor {
  top: 30px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text41 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text42 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3763 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-heartmedical {
  top: 30px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text43 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text44 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame380 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-frame1274 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-usersquare {
  top: 30px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text45 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text46 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3764 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-plane {
  top: 30px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text47 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text48 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame381 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
 
.tab-selector-2-frame1275 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-car {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text49 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text50 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-frame3765 {
  gap: 20px;
  display: flex;
  padding: 20px 16px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-neutral-100);
}
 
.tab-selector-2-home {
  top: 50px;
  left: 120px;
  width: 175px;
  height: 175px;
  position: absolute;
}
 
.tab-selector-2-text51 {
  color: var(--dl-color-neutral-800);
  height: auto;
  z-index: 1;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 
.tab-selector-2-text52 {
  color: var(--dl-color-neutral-500);
  height: auto;
  z-index: 2;
  align-self: stretch;
  text-align: left;
  line-height: normal;
}
 

 
@media(max-width: 1195px) {
  .tab-selector-2-product {
    width: 100%;
  }
  .tab-selector-2-frame133 {
    gap: 40px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40PX;
  }
  .tab-selector-2-frame362 {
    align-self: center;
  }
  .tab-selector-2-text10 {
    align-self: center;
  }
  .tab-selector-2-frame3731 {
    width: 100%;
    flex-direction: column;
  }
  .tab-selector-2-image1 {
    width: 100%;
    height: auto;
  }
  .tab-selector-2-frame3741 {
    width: 100%;
  }
  .tab-selector-2-frame3732 {
    width: 100%;
  }
  .tab-selector-2-image2 {
    width: 100%;
    height: 100%;
  }
  .tab-selector-2-frame3742 {
    width: 100%;
  }
  .tab-selector-2-image3 {
    width: 100%;
    height: 100%;
  }
  .tab-selector-2-frame3743 {
    width: 100%;
  }
}
 
@media(max-width: 845px) {
  .tab-selector-2-product {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .tab-selector-2-frame133 {
    gap: 24px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .tab-selector-2-frame362 {
    align-self: center;
  }
  .tab-selector-2-text10 {
    align-self: center;
  }
  .tab-selector-2-frame3731 {
    gap: 24px;
    flex-direction: column;
  }
  .tab-selector-2-image1 {
    width: 100%;
    height: 100%;
  }
  .tab-selector-2-frame3741 {
    width: 100%;
  }
  .tab-selector-2-frame3751 {
    gap: 16px;
    align-items: center;
  }
  .tab-selector-2-text21 {
    text-align: center;
  }
  .tab-selector-2-text22 {
    align-self: center;
    text-align: center;
  }
  .tab-selector-2-frame3732 {
    gap: 24px;
    align-items: center;
    flex-direction: column;
  }
  .tab-selector-2-image2 {
    width: 100%;
    height: 100%;
  }
  .tab-selector-2-frame3742 {
    width: 100%;
  }
  .tab-selector-2-frame3752 {
    align-self: center;
  }
  .tab-selector-2-text29 {
    align-self: center;
  }
  .tab-selector-2-text30 {
    text-align: center;
  }
  .tab-selector-2-text31 {
    align-self: center;
    text-align: center;
  }
  .tab-selector-2-frame3733 {
    gap: 24px;
    align-items: center;
    flex-direction: column;
  }
  .tab-selector-2-image3 {
    width: 100%;
    height: 100%;
  }
  .tab-selector-2-frame3743 {
    width: 100%;
  }
  .tab-selector-2-frame3753 {
    gap: 16px;
    align-self: center;
  }
  .tab-selector-2-text38 {
    align-self: center;
  }
  .tab-selector-2-text39 {
    align-self: center;
    text-align: center;
  }
  .tab-selector-2-text40 {
    align-self: center;
    text-align: center;
  }
}
